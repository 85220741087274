<template>
  <div class="reserve">
    <h3 class="title">Inserisci il codice per il ritiro</h3>

    <div class="form-error" v-if="error.length > 0">
      <span>{{ error }}</span>
    </div>

    <form action="#" @submit="handleInput">
      <div class="input-control">
        <input
          autocomplete="off"
          placeholder="----"
          type="text"
          name="barcode"
          v-model="barcode"
          ref="barcodeInput"
          readonly="readonly"
          @input="onInputChange"
        />
        <button type="submit">Go</button>
      </div>
    </form>
    <SimpleKeyboard @onChange="onChange" :input="barcode" />

    <div class="modal-backdrop" v-if="modalShow">
      <div class="modal">
        {{ modalText }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.reserve {
  width: 85%;
}
.reserve .simple-keyboard {
  transform: scaleY(1.4) translateY(10%);
}

h3.title {
  font-size: 42px;
  padding: 30px;
  font-family: "Anton";
  background-color: #ebebeb;
  color: #222;
  margin-bottom: 30px;
}

.form-error {
  font-size: 42px;
  padding: 30px;
  font-family: "Anton";
  background-color: rgba(red, 0.5);
  color: #222;
  margin-bottom: 30px;
}

.input-control {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

input {
  width: 90%;
  padding: 20px;
  border: 3px solid #ddd;
  border-radius: 0;
  font-size: 36px;
  flex-basis: 90%;
  height: 100px;
}

input:focus {
  outline: none;
  border: 3px dashed blue;
  border-radius: 10px 0 0 10px;
}

button {
  background-color: #ddd;
  border: 3px solid #ddd;
  color: #222;
  width: 100%;
  height: 100px;
  flex-basis: 15%;
  cursor: pointer;
  font-size: 28px;
  font-weight: bold;
}

form {
  margin-bottom: 30px;
}

.modal-backdrop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  z-index: 1000;
}

.modal {
  background-color: #333;
  color: #fff;
  border: 1px solid #222;
  padding: 20px;
  backdrop-filter: blur(10px);
  border-radius: 10px;
  font-size: 32px;
}
</style>

<script>
import SimpleKeyboard from "@/components/Keyboard";
import service from "@/api/service";
import lockerConfig from "../lockerConfig";

export default {
  name: "Leave A Package",
  components: {
    SimpleKeyboard,
  },
  data() {
    return {
      barcode: "",
      error: "",
      modalShow: false,
      modalText: "",
      polling: null,
    };
  },
  mounted() {
    if (this.$route.query.barcode) {
      if (!isNaN(this.$route.query.barcode)) {
        this.barcode = parseInt(this.$route.query.barcode).toString();
        this.handleInput(false);
      }
    }

    this.polling = setInterval(() => {
      this.focusInput();

      if (this.modalShow) {
        this.$refs.barcodeInput.disabled = true;
      } else {
        this.$refs.barcodeInput.disabled = false;
      }
    }, 0);
  },
  beforeUnmount() {
    clearInterval(this.polling);
  },
  methods: {
    pollDrawer: async function (drawerId, maxTry = 30, errorCount = 0) {
      if (maxTry <= 0) {
        return false;
      }

      try {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        const status = await apiService.getSolenoidStatus(drawerId);
        if (status == false) {
          return true;
        }

        return await this.pollDrawer(drawerId, maxTry - 1, errorCount);
      } catch (err) {
        if (errorCount >= 15) {
          return false;
        }

        return await this.pollDrawer(drawerId, maxTry, errorCount + 1);
      }
    },
    focusInput: function () {
      this.$refs.barcodeInput.focus();
    },
    handleInput: async function (e) {
      if (e) e.preventDefault();

      this.error = "";
      let barcode = this.barcode.trim();
      barcode = barcode.toUpperCase();

      if (barcode.length <= 0) {
        this.error = "Codice mancante!";
        return;
      }

      const barcodePrefix = barcode.substr(0, 3);
      const acceptedBarcodePrefixes = ["004", "005"];
      if (!acceptedBarcodePrefixes.includes(barcodePrefix)) {
        this.error = "Attenzione: codice errato";
        return;
      }

      this.modalText = "Checking the code...";
      this.modalShow = true;

      const isCodeCorrect = await service.checkCode(barcode);
      if (!isCodeCorrect) {
        this.error = "This barcode cannot be found in the database.";
        this.modalShow = false;
        return;
      }

      this.modalText = "Code is correct, redirecting you!";
      this.$router.push({
        name: "Receive/Submit",
        query: {
          barcode: barcode,
        },
      });
      return;

      // Redirect to select package size.

      /*
      this.error = "";
      this.barcode = this.barcode.trim();

      if (this.barcode.length <= 0) {
        this.error = "Please fill the field.";
        return;
      }

      this.modalText = "Processing the data...";
      this.modalShow = true;

      const drawers = await service.allDrawers();
      let foundId = -1;
      drawers.forEach((item, id) => {
        if (
          parseInt(item.barcodeNumber) == parseInt(this.barcode) &&
          item.status == lockerConfig.drawerStatus.AWAITING_PACKAGE
        ) {
          foundId = id;
        }
      });
      console.dir(drawers);

      if (foundId == -1) {
        this.modalText = "No drawers found with this barcode number.";
        setTimeout(() => (this.modalShow = false), 2000);
        return;
      }

      const drawerId = drawers[foundId].uniqueId;
      const slaveId = drawers[foundId].slaveId;
      const solenoidId = drawers[foundId].solenoidId;

      try {
        const open = await service.openDrawer(slaveId, solenoidId);
      } catch (err) {
        this.modalText = "System error while opening the associated drawer.";
        setTimeout(() => (this.modalShow = false), 2000);
        return;
      }
      this.modalText =
        "Apertura cassetto, ritirate il pacco e chiudete il cassetto";

      await new Promise((resolve) => setTimeout(resolve, 4000));

      const poll = await this.pollDrawer(slaveId, solenoidId);
      if (!poll) {
        this.modalText =
          "There was an error while getting the state of drawer, please close it and try again later.";
        setTimeout(() => (this.modalShow = false), 2000);
        return;
      }

      this.modalText = "Thanks, processing the data...";

      try {
        const setState = await service.leavePackage(drawerId);
        if (!setState) {
          throw false;
        }
      } catch (err) {
        this.modalText =
          "There was an error while processing drawer status, opening the drawer...";

        try {
          const open = await service.openDrawer(slaveId, solenoidId);
        } catch (err) {
          this.modalText = "System error while opening the associated drawer.";
          setTimeout(() => (this.modalShow = false), 2000);
          return;
        }

        this.modalText =
          "Sorry for the inconvenience, please try again later after closing the drawer...";
        setTimeout(() => (this.modalShow = false), 2000);
        return;
      }

      this.modalText = "Grazie per averci scelto!";
      setTimeout(() => {
        this.modalShow = false;

        this.$router.push({
          name: "Home",
        });
      }, 3000);
      return;
      */
    },
    onChange(input) {
      this.barcode = input;
    },
    onInputChange(input) {
      this.barcode = input.target.value;
    },
  },
};
</script>
